@import "./assets/theme";
@import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
@import "~font-awesome/css/font-awesome.css";
@import "~src/assets/variables";

#appleid-signin > div > div:last-child > svg {
  height: 44px;
  width: 100%;
}

.mat-form-field {
  margin-bottom: 5px;
}

.i18n-bold {
  font-weight: bold;
}

.mat-select-panel mat-option.mat-option {
  height: unset;
}

.ag-theme-alpine [col-id^='Reflectancia'] {
  background-color: rgba(0, 160, 153, 0.12);

}
.table-data-container .ag-theme-alpine .ag-row-selected,
.table-data-container .ag-theme-alpine .ag-row.row-validated {
  background-color: rgba(190, 231, 229, 1);
  border-radius: 25px;
  padding-top: -2px;
  padding-bottom: -2px;
}

//////////////////////
.table-data-container.ag-theme-alpine ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 14px 14px transparent;
  border: solid 4px transparent;
}

.table-data-container.ag-theme-alpine ::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 14px 14px $eyesnroad-green;
  border: solid 4px transparent;
  border-radius: 14px;
}

.table-data-container.ag-theme-alpine ::-webkit-scrollbar-button {
  display: none;
}

.mat-option-text.mat-option-text {
  white-space: normal;
}

.mat-form-field-label {
  display: none;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100%;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 400 !important;
  overflow: hidden;
}

.gray-background {
  background-color: #f0f1f7;
}

p {
  font-family: "Montserrat", sans-serif;
}

span,
a {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

a:active,
a:hover,
a:link,
a:visited {
  text-decoration: none !important;
}

.mat-button-wrapper {
  font-weight: 300 !important;
}

p {
  margin: 0 !important;
}

app-root {
  height: 100vh;
  display: block;
}

@media (max-width: 630px) {
  main {
    padding: 0 !important;
  }
}

img {
  width: min-content;
  height: min-content;
}

button {
  border: none;
}

.mat-checkbox-inner-container {
  height: 27px !important;
  width: 27px !important;
}

.mat-checkbox.mat-accent {
  .mat-checkbox-frame {
    border: 2px solid #f3f3f3;
  }

  &.mat-checkbox-checked .mat-checkbox-background {
    background-color: white;
    border: 2px solid #f3f3f3;
  }

  .mat-checkbox-checkmark-path {
    stroke: #4cd964 !important;
  }
}

.error-message {
  color: #dc3545;
  font-size: 15px;
}

a {
  cursor: pointer;
  text-decoration: none;
}

#chat {
  display: none;
}

.mat-button,
.mat-menu-item {
  outline: transparent !important;
}

.mat-menu-panel {
  min-width: 20px !important;
}

div:focus,
img:focus,
a:focus,
p:focus,
button:focus,
input:focus {
  outline: none;
}

.none {
  display: none !important;
}

.sm-block {
  display: block !important;
}

button {
  outline: none !important;
}

.row-validated {
  background-color: #00a099 !important;
}

div.invalid-feedback {
  display: flex;
}

@media (min-width: 630px) {
  html,
  body {
    height: 100%;
    overflow: visible;
  }

  app-root {
    height: 100% !important;
  }

  .md-block {
    display: block !important;
  }

  .md-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .md-none {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .lg-block {
    display: block !important;
  }

  .lg-none {
    display: none !important;
  }

  .lg-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
}


@include is414 {
}

@include is600 {
}

@include is768 {
}

@include is830 {
}

@include is834 {
}

@include is1024 {
}

@include is1194 {
}

@include is1280 {
}

@include is1536 {
}

@include is1600 {
}

@include is1900 {
}
