.dropzone, .dropzone * {
  box-sizing: border-box;
}

.dropzone {
  position: relative;
}
.dropzone .dz-preview {
  position: relative;
  display: inline-block;
  width: 120px;
  margin: 0.5em;
}
.dropzone .dz-preview .dz-progress {
  display: block;
  height: 15px;
  border: 1px solid #aaa;
}
.dropzone .dz-preview .dz-progress .dz-upload {
  display: block;
  height: 100%;
  width: 0;
  background: green;
}
.dropzone .dz-preview .dz-error-message {
  color: red;
  display: none;
}
.dropzone .dz-preview.dz-error .dz-error-message, .dropzone .dz-preview.dz-error .dz-error-mark {
  display: block;
}
.dropzone .dz-preview.dz-success .dz-success-mark {
  display: block;
}
.dropzone .dz-preview .dz-error-mark, .dropzone .dz-preview .dz-success-mark {
  position: absolute;
  display: none;
  left: 30px;
  top: 30px;
  width: 54px;
  height: 58px;
  left: 50%;
  margin-left: -27px;
}